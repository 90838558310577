/* eslint-disable max-len */
export default {
	"main_screen": {
		"Welcome to": "Welcome to",
		"Fantasy football for Italian top division.": "Fantasy football for Italian top division.",
		"At long last, Serie A gets the fantasy football game it deserves.": "At long last, Serie A gets the fantasy football game it deserves.",
		"Build your own 15-man team": "Build your own 15-man team",
		"Join common and private leagues": "Join common and private leagues",
		"Win great prizes": "Win great prizes",
    "announcement_banner_text": "Our mobile application is here! <0>Download now</0>"
	},
	"field_positions": {
		"goalkeeper": "goalkeeper",
		"defender": "defender",
		"midfielder": "midfielder",
		"forward": "forward",
	},
	"action_types": {
		"GS_OP": "Goals scored from open play",
		"GS_PK": "Goals scored from penalty",
		"AS": "Assists",
		"PE": "Penalties earned",
		"SoT": "Shots on target (every 2)",
		"CS_HT": "Clean sheets for every half-time",
		"TBI": "Tackles / blocks / interceptions (every 3)",
		"SA_OP": "Saves from open play (every 2)",
		"SA_PK": "Saves from penalty",
		"GC": "Goals conceded",
		"OG": "Own goals",
		"PM": "Penalties missed",
		"YC": "Yellow cards",
		"RC": "Red cards"
	},
	"action_types_long": {
		"minutes_played": "Minutes played",
		"GS_OP": "Goals scored from open play",
		"GS_PK": "Goals scored from penalty",
		"AS": "Assists",
		"PE": "Penalties earned",
		"SoT": "Shots on target (every 2)",
		"CS_HT": "Clean sheets for every half-time",
		"TBI": "Tackles / blocks / interceptions (every 3)",
		"SA_OP": "Saves from open play (every 2)",
		"SA_PK": "Saves from penalty",
		"GC": "Goals conceded",
		"OG": "Own goals",
		"PM": "Penalties missed",
		"YC": "Yellow cards",
		"RC": "Red cards"
	},
	"action_types_short": {
		"Round": "Round",
		"Contro": "Contro",
		"Points": "Points",
		"MP": "MP",
		"GS_OP": "GS OP",
		"GS_PK": "GS PK",
		"AS": "AS",
		"PE": "PE",
		"SoT": "SoT",
		"CS_HT": "CS HT",
		"TBI": "TBI",
		"SA_OP": "SA OP",
		"SA_PK": "SA PK",
		"GC": "GC",
		"OG": "OG",
		"PM": "PM",
		"YC": "YC",
		"RC": "RC",
		"Season": "Season",
	},
	"errors": {
		"TEAM_NAME_IS_REQUIRED": "Team Name is required",
		"TEAM_NAME_BAD_WORDS": "Team Name should not contain inappropriate/offensive words",
		"TEAM_NAME_ONLY_LATIN_OR_DIGITS": "Team Name should contain only latin letters or digits",
		"TEAM_NAME_SHOULD_BE_LESS_31": "Team Name can't be longer than 30 characters"
	},
	"validate_user_team": {
		"team_budget_is_overflown": "Team budget is overflown",
		"max_players_from_one_club": "Team should contain maximum {{playersFromOneClubLimit}} players from single club:",
		"team_should_have_a_captain_selected": "Team should have a captain selected",
		"team_should_have_a_vice_captain_selected": "Team should have a vice-captain selected",
		"captain_and_vice_captain_can_t_be_the_same": "Captain and vice-captain can't be the same",
		"team_should_contain_15_players_for_all_positions_on_the_pitch": "Team should contain 15 players for all positions on the pitch",
	},
	"change_password_modal": {
		"title": "Change password",
		"Old Password": "Old Password",
		"New Password": "New Password",
		"Confirm New Password": "Confirm New Password",
		"Save": "Save",
		"Password": "Password",
	},
	"delete_account_modal": {
		"Confirm Account Deletion": "Confirm Account Deletion",
		"All your personal details and all the other account related data will be permanently removed. Are you sure ?": "All your personal details and all the other account related data will be permanently removed. Are you sure ?",
		"confirm": "confirm",
	},
	"forgot_password_modal": {
		"forgot password": "forgot password",
		"Please enter your email for password reset": "Please enter your email for password reset",
		"Email": "Email",
		"send": "send",
		"Email has been successfully sent": "Email has been successfully sent",
		"Please check your email to reset your password": "Please check your email to reset your password",
	},
	"set_new_password_modal": {
		"reset password": "reset password",
		"Please enter your new password": "Please enter your new password",
		"New password": "New password",
		"Confirm New Password": "Confirm New Password",
		"send": "send",
		"Password has been successfully changed": "Password has been successfully changed",
		"sign in": "sign in"
	},
	"sign_in_modal": {
		"sign in": "sign in",
		"Email or Password is invalid": "Email or Password is invalid",
		"Email": "Email",
		"Password": "Password",
		"Forgot your password ?": "Forgot your password ?"
	},
	"deadline": {
		"deadline": "deadline"
	},
	"game_info_modal": {
		"Match is scheduled": "Match is scheduled",
		"Match is cancelled": "Match is cancelled",
		"Match is forfeited": "Match is forfeited",
		"Match data is being added": "Match data is being added",
	},
	"maintenance": {
		"Down for maintenance. Stay with us": "Down for maintenance. Stay with us",
	},
	"not_found_page": {
		"Page not found": "Page not found",
	},
	"own_private_league_settings_modal": {
		"Edit League": "Edit League",
		"League Code": "League Code",
		"Close entries to the league": "Close entries to the league",
		"Delete League": "Delete League",
		"Save": "Save",
		"Team successfully removed from this private league": "Team successfully removed from this private league",
		"Enter team name to remove": "Enter team name to remove",
		"Loading...": "Loading...",
		"No teams found": "No teams found",
		"Search and select the team to remove from this private league": "Search and select the team to remove from this private league",
		"Remove team": "Remove team",
	},
	"player_info_modal": {
	    "doubtful": "Doubtful",
		"fit": "Fit",
		"international-duty": "International duty",
		"minor-injury": "Minor injury",
		"serious-injury": "Serious injury",
		"suspended": "Suspended",
		"transferred-out": "Transferred out",
		"unhealthy": "Unhealthy",
		"forward": "forward",
		"midfielder": "midfielder",
		"defender": "defender",
		"goalkeeper": "goalkeeper",
		"Round": "Round",
		"total": "total",
		"score": "score",
		"selected by": "selected by {{percent}}%",
		"This player has not played any matches this season yet": "This player has not played any matches this season yet",
		"roundResults": "Round results",
		"fixtures": "Fixtures",
		"pastSeasonResults": "Season results",
		"Date": "Date",
		"Fixture": "Fixture",
		"command_vs_command": "<0>{{team1}}</0> vs <1>{{team2}}</1>",
	},
	"private_league_delete_modal": {
		"Confirm league removal": "Confirm league removal",
		"This private league will be permanently removed. Are you sure ?": "This private league will be permanently removed. Are you sure ?",
		"confirm": "confirm",
	},
	"private_league_settings_modal": {
		"Confirm league exit": "Confirm league exit",
		"Your team will be permanently removed from this Private League. Are you sure ?": "Your team will be permanently removed from this Private League. Are you sure ?",
		"confirm": "confirm",
	},
	"round_statistic": {
		"round results": "Round {{roundNumber}} <0>results</0>",
		"Points": "Points",
		"transfers/deduction": "Transfers <0>(Deduction)</0>",
		"WC": "WC",
		"Round Average": "Round Average",
		"Round Highest": "Round Highest",
	},
	"status": {
		"Live": "Live",
	},
	"total_statistic": {
		"season_results": "Season <0>results</0>",
		"Total Points": "Total Points",
		"Total Transfers": "Total Transfers",
		"Season Average": "Season Average",
		"Team Price": "Team Price",
	},
	"contact_us": {
		"This field is required": "This field is required",
		"First Name": "First Name",
		"Last Name": "Last Name",
		"Email": "Email",
		"What's this about": "What's this about",
		"Message": "Message",
		"Send": "Send",
		"Company": "Sportsyte OU",
		"For marketing/sponsorship": "For marketing/sponsorship",
		"Contact us": "Contact us",
		"Email has been successfully sent": "Email has been successfully sent",
		"Thank you for contacting us": "Thank you for contacting us",
		"We will get in touch soon": "We will get in touch soon",
		"contact_us": "Contact <0>Us</0>",
		"contact_details": "Contact <0>Details</0>",
		"Bug": "Bug",
		"Functionality change recommendation": "Functionality change recommendation",
		"New functionality recommendation": "New functionality recommendation",
		"Incorrect match entry": "Incorrect match entry",
		"Other": "Other",
	},
	"players_list": {
		"Points": "Points",
		"points": "points",
		"Price": "Price",
		"selected_by": "Selected by %",
		"minutes_played": "Minutes played",
		"goals_scored_from_open_play": "Goals scored from open play",
		"goals_scored_from_penalty": "Goals scored from penalty",
		"Assists": "Assists",
		"penalties_earned": "Penalties earned",
		"shots_on_target": "Shots on target",
		"clean_sheets_for_every_half_time": "Clean sheets for every half-time",
		"Tackles/blocks/interceptions": "Tackles/blocks/interceptions",
		"saves_from_open_play": "Saves from open play",
		"saves_from_penalty": "Saves from penalty",
		"goals_conceded": "Goals conceded",
		"own_goals": "Own goals",
		"penalties_missed": "Penalties missed",
		"yellow_cards": "Yellow cards",
		"red_cards": "Red cards",
		"Search": "Search",
		"Team": "Team",
		"Sort by": "Sort by",
		"players": "players",
		"budget": "budget",
		"transfers": "transfers",
		"deduction": "deduction",
		"FWD": "FWD",
		"MID": "MID",
		"DEF": "DEF",
		"GKP": "GKP",
	},
	"footer": {
		"Footer Text": "© Sportsyte OU. All rights reserved",
		"Privacy Policy": "Privacy Policy",
	},
	"header": {
		"Home": "Home",
		"Leagues": "Leagues",
		"Schedule": "Schedule",
		"News": "News",
		"Prizes": "Prizes",
		"Rules & FAQ": "Rules & FAQ",
		"Contact Us": "Contact Us",
		"Sign In": "Sign In",
		"Sign Up": "Sign Up",
		"Announcement": "Download app",
	},
	"nav_item_dropdown": {
		"Next Round": "Next Round",
		"Live & Previous Rounds": "Live & Previous Rounds",
		"Transfers": "Transfers",
		"Team": "Team",
	},
	"profile_icon": {
		"Personal Details": "Personal Details",
		"Sign Out": "Sign Out",
	},
	"dream_team": {
		"dream_team": "Dream <0>Team</0>",
	},
	"sign_in_up_buttons": {
		"Logout": "Logout",
		"Sign In": "Sign In",
		"Sign Up": "Sign Up",
	},
	"sign_up_block": {
		"Create your own 15-man squad": "Create your own 15-man squad",
		"Sign Up": "Sign Up",
	},
	"tabs": {
		"Round": "Round",
		"Season": "Season",
	},
	"copy_icon": {
		"League Code copied to clipboard": "League Code copied to clipboard",
	},
	"leagues": {
		"create new league": "create new league",
		"join league": "join league",
		"leagues": "leagues",
	},
	"leagues_list": {
		"common_header": "Common <0>Leagues</0>",
		"private_header": "Private <0>Leagues</0>",
		"members": "{{number}} members",
		"Your team is not participating in Private Leagues.": "Your team is not participating in Private Leagues.",
		"Create or join Private Leagues now": "Create or join Private Leagues now",
	},
	"leagues_modal": {
		"join_title": "Join League",
		"create_title": "Create League",
		"League Code": "League Code",
		"League Name": "League Name",
		"Point count starts from Round": "Point count starts from Round",
		"round": "Round {{number}}"
	},
	"leagues_ranking": {
		"Loading...": "Loading...",
		"select": "Select {{item}}",
		"members": "{{count}} members",
		"League Code": "League Code",
		"All the members will appear in this league once the Round starts": "All the members will appear in this league once the Round starts",
		"Update": "Update",
		"Count from": "Count from",
		"round": "Round {{number}}",
		"title_private": "Private <0>League</0>",
		"title_round": "Round <0>League</0>",
		"title_season": "Season <0>League</0>",
		"title_quad": "Quad <0>League</0>",
		"title_country": "{{leagueName}} <0>League</0>",
	},
	"members_list": {
		"place": "place",
		"manager name": "manager name",
		"team name": "team name",
		"points": "points",
	},
	"live_previous_round_teams": {
		"There are currently no live or finished Rounds in current season": "There are currently no live or finished Rounds in current season",
		"Previous round": "Previous round",
		"Round": "Round",
		"Next round": "Next round",
		"Team not played": "Your team hasn't played in this Round",
	},
	"news_and_rankings": {
		"news_and_rankings": "News & <0>Rankings</0>",
	},
	"welcome": {
		"Hello,": "Hello,",
	},
	"news": {
		"Read more...": "Read more...",
	},
	"news_and_article": {
		"news_and_article": "News & <0>Article</0>",
	},
	"news_and_social" :{
		"news_and_social": "News & <0>Social</0>",
	},
	"numbers": {
		"Season users": "Season users",
		"Country Leagues": "Country Leagues",
		"Private Leagues": "Private Leagues",
	},
	"news_and_numbers": {
		"news_and_numbers": "News & <0>Numbers</0>",
	},
	"next_round_team": {
		"deadline_before": "Deadline before Round {{number}}",
		"Reset Team": "Reset Team",
		"Confirm team": "Confirm team",
		"Team is successfully changed": "Team is successfully changed",
		"round_results_title": "Round <0>Schedule</0>",
	},
	"next_season_team": {
		"Unfortunately there was trouble validating your team. Create or finalize your team before proceeding": "Unfortunately there was trouble validating your team. Create or finalize your team before proceeding",
		"Team should be validated using \"Save Team\" button before proceeding": "Team should be validated using \"Save Team\" button before proceeding",
		"Team should be saved using \"Save Team\" button before proceeding": "Team should be saved using \"Save Team\" button before proceeding",
		"Formation error. Please check your connection or contact us": "Formation error. Please check your connection or contact us",
		"Random Team": "Random Team",
		"Deadline before Round": "Deadline before Round {{number}}",
		"Reset Team": "Reset Team",
		"Team Name": "Team Name",
	},
	"other_user_team": {
		"There are currently no live or finished Rounds in current season": "There are currently no live or finished Rounds in current season",
		"Previous round": "Previous round",
		"Round": "Round",
		"Next round": "Next round",
		"Team not played": "This team hasn’t played this Round"
	},
	"place": {
		"first_place": "<0>1st</0> place",
		"second_place": "<0>2nd</0> place",
		"third_place": "<0>3rd</0> place",
	},
	"prizes": {
		"Prizes": "Prizes",
	},
	"prizes_tabs": {
		"Round winner": "Round league winner",
		"Quad round winner": "Quad league winner",
		"Season winner": "Season league winner",
	},
	"registration_completed": {
	    "email_verification_success": "Email successfully <0>verified</0><<1>You can continue on the current device or on the device you initiated email verification</1>",
	    "email_verification_error": "Email verification <0>failed</0><1>Please try again or contact us to report the issue</1>",
	    "email_has_been_verified": "Email has already <0>been verified</0><1>You can continue on the current device or on the device you initiated email verification</1>",
	    "email_verification_link_expired": "Email verification link <0>has expired</0><1>Sign in to request a new link to verify your email</1>",
	    "Start Fantasy": "Start Fantasy",
	    "download_app_success": "Thank you for downloading <0>the app</0>",
	    "download_app_error": "Unfortunately <0>app</0> isn't available on your device",
	},
	"confirm_email": {
		"check_email": "Confirmation email has been sent to <0>{{email}}.</0> Please check your inbox to verify your email",
		"Havent received email ?": "Haven’t received email ?",
		"Check your spam folder or click below to resend verification email": "Check your spam folder or click below to resend verification email",
		"resend email": "resend email",
		"Email has been successfully sent": "Email has been successfully sent",
	},
	"registration_form": {
		"changed_fields_success_message_one": "{{changedFieldsNames}} has been successfully changed",
		"changed_fields_success_message_other": "{{changedFieldsNames}} have been successfully changed",
		"First Name": "First Name",
		"Last Name": "Last Name",
		"Team Name": "Team Name",
		"Email": "Email",
		"Country": "Country",
		"Password": "Password",
		"Change Password": "Change Password",
		"Confirm Password": "Confirm Password",
		"Delete Account": "Delete Account",
		"agreement": "Check here to indicate that you have read and agree to the <0>Terms of Use</0>",
		"save": "save",
		"register": "register",
		"You are enrolled to the league of selected country. Country cant be changed during season": "You are enrolled to the league of selected country. Country can’t be changed during season",
		"You will be enrolled to the league of selected country. Country cant be changed during season": "You will be enrolled to the league of selected country. Country can’t be changed during season",
	},
	"personal_details": {
		"personal_details": "Personal <0>Details</0>",
		"Team Name": "Team Name",
		"First Name": "First Name",
		"Last Name": "Last Name",
		"Country": "Country",
		"save": "save",
		"You will be enrolled to the league of selected country. Country cant be changed during season": "You will be enrolled to the league of selected country. Country can’t be changed during season",
	},
	"players_list_item": {
		"Added to the pitch": "Added to the pitch",
	},
	"progress_block": {
		"Select Team": "Select Team",
		"Personal Details": "Personal Details",
		"Confirm Email": "Confirm Email",
		"start fantasy": "start fantasy",
	},
	"progress_next_season": {
		"welcome": "Welcome to <0>{{seasonName}}</0> Season",
		"Select Team": "Select Team",
		"Personal Details": "Personal Details",
	},
	"sign_up_closed": {
		"season": "2024-2025",
		"startTime": "July",
		"sign_up_closed": "<0>Sign Up</0> for season <0>{{season}}</0> will open in <0>{{startTime}}</0>",
	},
	"select_team": {
		"Unfortunately there was trouble validating your team. Create or finalize your team before proceeding": "Unfortunately there was trouble validating your team. Create or finalize your team before proceeding",
		"Team should be validated using \"Save Team\" button before proceeding": "Team should be validated using \"Save Team\" button before proceeding",
		"Team should be saved using \"Save Team\" button before proceeding": "Team should be saved using \"Save Team\" button before proceeding",
		"Formation error. Please check your connection or contact us": "Formation error. Please check your connection or contact us",
		"Random Team": "Random Team",
		"Deadline before Round": "Deadline before Round {{round}}",
		"Reset Team": "Reset Team",
		"Team Name": "Team Name",
	},
	"rules_and_faq": {
		"rules_and_faq": "Rules & <0>faq</0>",
	},
	"rules_tab": {
		"Loading...": "Loading...",
	},
	"rules_and_faq_tabs": {
		"how to play": "how to play",
		"rules": "rules",
		"FAQ": "FAQ",
	},
	"round_item": {
		"Round": "Round {{round}}",
		"completed": "completed",
		"cancelled": "cancelled",
	},
	"schedule_and_results": {
		"schedule_and_results": "Schedule & <0>Results</0>",
	},
	"confirm_wildcard_modal": {
		"Confirm Wildcard": "Confirm Wildcard",
		"Confirmation Text": "Your team will have unlimited transfers before next Round. Once activated, Wildcard cannot be cancelled.",
		"Confirm": "Confirm",
	},
	"transfers_page": {
		"Wildcard has already been used this season": "Wildcard has already been used this season",
		"Wildcard": "Wildcard",
		"Deadline": "Deadline before Round {{round}}",
		"Reset Team": "Reset Team",
		"Confirm transfers": "Confirm transfers",
		"Transfers are successfully completed": "Transfers are successfully completed",
		"confirm transfers": "confirm transfers",
		"Transfers Out": "Transfers Out",
		"Transfers In": "Transfers In",
		"confirm": "confirm",
		"round_results_title": "Round <0>Schedule</0>",
	},
	"auth_errors": {
		"email_is_required": "Email is required",
		"email_should_have_correct_format_email_example_com": "Email should have correct format, email@example.com",
		"password_is_required": "Password is required",
		"please_use_correct_email_address": "Please, use correct email address",
	},
	"dream_team_errors": {
		"no_season_team": "No dream team for finished season: {{season}}",
		"no_round_team": "No dream team for finished round: {{round}}",
		"invalid_id": "Invalid round ID"
	},
	"private_leagues_errors": {
		"league_code_is_required": "League Code is required",
	},
	"network_errors": {
		"server_error": "Server error",
		"error_500": "Error 500",
		"status": "Status: {{status}}",
		"code": "Code: {{code}}",
		"wrong_request_url": "Wrong request URL",
	},
	"validation_errors": {
		"email_should_have_correct_format_email_example_com": "Email should have correct format, email@example.com",
		"password_requirements": "Password should contain at least 8 characters, 1 number, 1 lower and 1 uppercase letter",
		"password_mismatch": "Password mismatch",
		"should_contain_only_latin_letters": "{{field}} should contain only latin letters",
		"should_not_contain_inappropriate_offensive_words": "{{field}} should not contain inappropriate/offensive words",
		"can_t_be_longer_than_30_characters": "{{field}} can’t be longer than 30 characters",
		"first_name": "First Name",
		"is_required": "{{field}} is required",
		"last_name": "Last Name",
		"email": "Email",
		"country": "Country",
		"password": "Password",
		"confirm_password": "Confirm password",
		"team_name": "Team name",
		"old_password": "Old Password",
		"league_name_can_t_be_longer_than_30_characters": "League name can't be longer than 30 characters",
		"league_name": "League name",
		"this_field": "This field",
		"league_code": "League code",
	},
	"switch": {
		"switchRound": "Round <0>{{value}}</0>",
		"switchSeason": "Season <0>{{value}}</0>",
	},
	"round_results": {
		"title": "Round <0>results</0>",
	},
	"field_player_item": {
		"FWD": "FWD",
		"MID": "MID",
		"DEF": "DEF",
		"GKP": "GKP",
	}
};