import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col, Spinner } from "reactstrap";
import { connect } from "react-redux";
import { useMediaQuery } from "react-responsive";
import PitchMobile from "static/assets/images/m-pitch.svg";
import PitchSmall from "static/assets/images/pitch-small.svg";
import Shape from "static/assets/images/shape-empty-field.svg";
import logoLarge from "static/assets/images/logo-large.svg";
import { useTranslation } from "react-i18next";

import {
	footballRoundPropType, roundGamePropType,
} from "utils/propTypes";
import { parsedFieldTeamType } from "utils/propTypes/footballTeam";
import { userFootballPlayerType } from "utils/propTypes/footballPlayer";

import {
	actionInitTeam, actionResetTeam,
} from "redux/actions/footballField";
import {
	getFinishedAndLiveRounds,
	getFinishedRounds,
	getRunningRound,
} from "redux/selectors";
import { actionGetUserRoundTeamsRequest } from "redux/actions/userRoundTeams";
import { actionClearRoundGamesHashList } from "redux/actions/roundGames";
import { actionGetOtherUserResultsRequest } from "redux/actions/otherUserResults";

import FootballFieldContainer from "modules/footballField/containers/FootballFieldContainer";
import SpinnerButton from "modules/common/SpinnerButton";
import LeaguesList from "modules/leagues/components/LeaguesList";
import RoundStatistic from "modules/common/RoundStatistic";
import TotalStatistic from "modules/common/TotalStatistic";
import Status from "modules/common/Status";
import AnnouncementBanner from "modules/common/AnnouncementBanner";

import AdUnit from "modules/common/AdUnit"

const OtherUserTeamContainer = ({
	isLoading, fieldTeam, seasonRoundsDataUpdateTime,
	actionInitTeam, otherUserResultsHashList, actionClearRoundGamesHashList,
	actionGetUserRoundTeamsRequest,
	currentUserId, usersRoundsTeamsHashList,
	actionGetOtherUserResultsRequest, liveAndFinishedRounds, actionResetTeam,
	usersRoundsTeamsInited
}) => {
	const { t } = useTranslation("other_user_team");
	const [selectedRound, setSelectedRound] = useState({});
	const [isExistNext, setIsExistNext] = useState({});
	const [isExistPrevious, setIsExistPrevious] = useState({});
	const [previousUserName, setPreviousUserName] = useState("");
	const [previousTeamName, setPreviousTeamName] = useState("");

	const selectedRoundID = selectedRound.id;
	const urlParams = new URLSearchParams(window.location.search);
	const userIdFromUrl = parseInt(urlParams.get("user_id"));
	const userID = userIdFromUrl || currentUserId;
	const userRoundTeamForSelectedRound = usersRoundsTeamsHashList && userID && selectedRoundID && usersRoundsTeamsHashList[userID] && usersRoundsTeamsHashList[userID][selectedRoundID];
	const isEmptyTeamForThisRound = userRoundTeamForSelectedRound?.isEmpty;
	const {
		total_points: totalPoints,
		transfers_count: usedTransfers,
		team_price: teamPrice,
		average_points: averagePoints,
		user_first_name: userFirstName,
		user_last_name: userLastName,
		user_team_name: userTeamName = previousTeamName,
	} = otherUserResultsHashList[userID] || {};
	const userFullName = `${userFirstName} ${userLastName}` || previousUserName;
	const isNoLiveAndPreviousRoundsForThisSeason = seasonRoundsDataUpdateTime && !liveAndFinishedRounds.length;

	useEffect(() => {
		actionClearRoundGamesHashList();
		return () => {
			actionClearRoundGamesHashList();
		};
	}, []);

	useEffect(() => {
		if (userID && otherUserResultsHashList && !otherUserResultsHashList[userID]) {
			actionGetOtherUserResultsRequest({
				user: userIdFromUrl || currentUserId,
			});
		}
	}, [userID]);

	useEffect(() => {
		if (selectedRoundID && userID && (!usersRoundsTeamsHashList[userID] || !usersRoundsTeamsHashList[userID][selectedRoundID])) {
			actionGetUserRoundTeamsRequest({
				user: userID,
				football_round: selectedRoundID,
			});
		}
	}, [selectedRoundID, userID]);

	useEffect(() => {
		if (liveAndFinishedRounds && liveAndFinishedRounds.length) {
			setSelectedRound(liveAndFinishedRounds[liveAndFinishedRounds.length - 1]);
		}
	}, [liveAndFinishedRounds]);

	useEffect(() => {
		if (userRoundTeamForSelectedRound?.id) {
			const roundUserTeam = {
				...userRoundTeamForSelectedRound,
				football_players: userRoundTeamForSelectedRound.football_players,
				name: userRoundTeamForSelectedRound.user_team_name,
			};
			actionInitTeam(roundUserTeam);
		} else if (userRoundTeamForSelectedRound?.isEmpty) {
			actionResetTeam();
		}
	}, [userRoundTeamForSelectedRound]);

	useEffect(() => {
		if (selectedRoundID && liveAndFinishedRounds) {
			const currentRoundIndex = liveAndFinishedRounds.findIndex(round => round.id === selectedRoundID);
			setIsExistNext(liveAndFinishedRounds[currentRoundIndex + 1]);
			setIsExistPrevious(liveAndFinishedRounds[currentRoundIndex - 1]);
		}
	}, [selectedRoundID, liveAndFinishedRounds]);

	useEffect(() => {
		const currentFootballPlayersIDs = [];
		Object.keys(fieldTeam).forEach(line => {
			fieldTeam[line].forEach(player => {
				const { id: playerID } = player;
				playerID && currentFootballPlayersIDs.push(playerID);
			});
		});
	}, [fieldTeam, usersRoundsTeamsInited]);

	const isMobileResolution = useMediaQuery({
		query: "(max-device-width: 767px)"
	});

	const changeSelectedRound = value => {
		const currentRoundIndex = liveAndFinishedRounds.findIndex(round => round.id === selectedRoundID);
		const newRoundIndex = currentRoundIndex + value;
		if (newRoundIndex < liveAndFinishedRounds.length && newRoundIndex >= 0) {
			setSelectedRound(liveAndFinishedRounds[newRoundIndex]);
		}
	};

	if (userFullName !== previousUserName) {
		setPreviousUserName(userFullName);
	}

	if (userTeamName !== previousTeamName) {
		setPreviousTeamName(userTeamName);
	}

	const allDataIsLoaded = isEmptyTeamForThisRound || (selectedRoundID && userID && otherUserResultsHashList[userID] && userRoundTeamForSelectedRound);

	return (
		<div className="position-relative">
		  <AdUnit />
			{isNoLiveAndPreviousRoundsForThisSeason ?
				<Container className="d-flex align-items-center flex-column">
					<div className="blue-plank-top-service" />
					<div className="service-message">
						{t("There are currently no live or finished Rounds in current season")}
						<img src={logoLarge} alt="" />
					</div>
				</Container>
				: null}
			<Container key="lpr-1" className="select-team-container">
				<Row className='select-team-row'>
				  <Col className="select-team-side-column col-12 col-md-3">
            <div>
              <TotalStatistic
                transfersAmount={usedTransfers}
                teamPrice={teamPrice}
                totalPoints={totalPoints}
                averagePoints={averagePoints}
                isLoading={!otherUserResultsHashList[userID]}
              />
            </div>
            <div>
              <RoundStatistic selectedRound={selectedRound} />
            </div>
            <LeaguesList typeLeagues="common" />
          </Col>
					<Col className="select-team-pitch-column col-12 col-md-9">
						<Row
							className='select-team-top-controls'>
							<SpinnerButton
								disabled={!isExistPrevious}
								isLeftNavigate
								text={t("Previous round")}
								isLoading={isLoading}
								onClick={() => changeSelectedRound(-1)}
								className={`select-team-button text-uppercase double ${!isExistPrevious && "invisible"}`}
								size="medium"
								color="white"
							/>
							<span className='d-flex flex-column align-items-center justify-content-center'>
								<h1 className="font-h4new-h3">
									{t("Round")}&nbsp;
									<span className="color-accent1">
										{selectedRound && selectedRound.number || ""}
									</span>
								</h1>
								<div className="live-previous-running-round" >
									{selectedRound && selectedRound.status === "live" && <Status />}
								</div>
							</span>
							<SpinnerButton
								disabled={!isExistNext}
								isRightNavigate
								isLoading={isLoading}
								text={t("Next round")}
								onClick={() => changeSelectedRound(1)}
								className="select-team-button text-uppercase hidable-when-disabled double"
								size="medium"
								color="white"
							/>
						</Row>
						{isEmptyTeamForThisRound ?
							<div className="football-field-container-small">
								<img className="football-field-image-empty" src={Shape} alt="" />
								<span className="football-field-image-empty-text">{t("Team not played")}</span>
								<img className="football-field-image" src={isMobileResolution ? PitchMobile : PitchSmall} alt="" />
							</div> :
							<FootballFieldContainer isLiveAndPreviousPage selectedRoundId={selectedRoundID} />
						}
						<Row className='team-name-text mb out'>
              <Col>
                <h3 className="font-h4new text-center mb-1">{userTeamName}</h3>
                <h4 className="font-h6_bold text-center color-secondary-text">{userFullName}</h4>
              </Col>
            </Row>
					</Col>
				</Row>
				<AnnouncementBanner />
			</Container>
		</div>
	);
};

const mapStateToProps = (state) => {
	const { userDetails: { id: currentUserId } } = state.authReducer;
	const { usersRoundsTeamsHashList, userRoundsResultsHashList, usersRoundsTeamsListDataUpdateTime, usersRoundsTeamsInited } = state.userRoundTeamsReducer;

	const { fieldTeam } = state.footballFieldReducer;
	const { seasonRoundsDataUpdateTime } = state.seasonRoundsReducer;
	const isLoading = !usersRoundsTeamsListDataUpdateTime;
	const liveAndFinishedRounds = getFinishedAndLiveRounds(state);
	const { otherUserResultsHashList = {} } = state.otherUserResultsReducer;
	return {
		liveRound: getRunningRound(state),
		previousRounds: getFinishedRounds(state),
		leagueSeasons: state.leagueSeasonsReducer.seasonsListData,
		isLoading,
		fieldTeam,
		currentUserId,
		liveAndFinishedRounds,
		usersRoundsTeamsHashList,
		userRoundsResultsHashList,
		otherUserResultsHashList,
		usersRoundsTeamsInited,
		seasonRoundsDataUpdateTime
	};
};

export default connect(
	mapStateToProps,
	{
		actionInitTeam,
		actionGetUserRoundTeamsRequest,
		actionClearRoundGamesHashList,
		actionResetTeam,
		actionGetOtherUserResultsRequest,
	}
)(OtherUserTeamContainer);

OtherUserTeamContainer.propTypes = {
	userTeamFootballPlayers: PropTypes.arrayOf(userFootballPlayerType),
	fieldTeam: PropTypes.shape(parsedFieldTeamType).isRequired,
	currentRound: PropTypes.shape(footballRoundPropType.round),
	isLoading: PropTypes.bool.isRequired,
	seasonRoundsDataUpdateTime: PropTypes.number,
	liveRound: PropTypes.shape(footballRoundPropType.round),
	previousRounds: PropTypes.arrayOf(PropTypes.shape(footballRoundPropType.round).isRequired),
	usersRoundsTeamsHashList: PropTypes.object,
	actionInitTeam: PropTypes.func.isRequired,
	actionGetUserRoundTeamsRequest: PropTypes.func.isRequired,
	actionResetTeam: PropTypes.func.isRequired,
	actionGetOtherUserResultsRequest: PropTypes.func.isRequired,
	actionClearRoundGamesHashList: PropTypes.func.isRequired,
	currentUserId: PropTypes.string.isRequired,
	totalPoints: PropTypes.number.isRequired,
	transfersCount: PropTypes.number.isRequired,
	teamPrice: PropTypes.number.isRequired,
	liveAndFinishedRounds: PropTypes.array.isRequired,
	otherUserResultsHashList: PropTypes.object.isRequired,
	userRoundsResultsHashList: PropTypes.shape(PropTypes.shape(roundGamePropType.resultGame)),
	usersRoundsTeamsInited: PropTypes.bool.isRequired,
};

OtherUserTeamContainer.displayName = "outcon";