import React from "react";
import PropTypes from "prop-types";
import { Container } from "reactstrap";

import Title from "modules/common/Title";
import RoundList from "./RoundList";

import AdUnit from "modules/common/AdUnit"

const ScheduleAndResults = ({
	footballRounds,
}) => {
	return (
	  <div>
	    <AdUnit />
      <Container className="global-container">
        <Title translation={{
          ns: "schedule_and_results",
          key: "schedule_and_results"
        }} />
        <RoundList footballRounds={footballRounds} />
      </Container>
    </div>
	);
};

ScheduleAndResults.propTypes = {
	footballRounds: PropTypes.arrayOf(PropTypes.object),
};

export default ScheduleAndResults;

ScheduleAndResults.displayName = "sar";