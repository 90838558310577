import React, { useState } from "react";
import PropTypes from "prop-types";
import { Container, Row, Col } from "reactstrap";
import { useMediaQuery } from "react-responsive/src";
import { useTranslation } from "react-i18next";

import LeaguesModal from "./LeaguesModal";

import LeaguesList from "modules/leagues/components/LeaguesList";
import MainButton from "modules/common/MainButton";
import Title from "modules/common/Title";

import AdUnit from "modules/common/AdUnit"

const Leagues = () => {
	const { t } = useTranslation("leagues");
	const [ createOrJoinLeagueModalState, setCreateOrJoinLeagueModalState ] = useState(false);

	const handleToggle = (modalName) => {
		modalName && setCreateOrJoinLeagueModalState(modalName);
		createOrJoinLeagueModalState && setCreateOrJoinLeagueModalState(false);
	};

	const isCreateLeague = createOrJoinLeagueModalState === "create";

	const sendProps = {
		openModalState: createOrJoinLeagueModalState,
		toggle: handleToggle,
		type: isCreateLeague ? "create" : "join"
	};

	const isMobileResolution = useMediaQuery({
		query: "(max-width: 767px)"
	});

	return (
		<div className="leagues-page">
		  <AdUnit />
			<Container className="global-container">
        <Row>
          <Title translation={{
              ns: "leagues",
              key: "leagues"
          }} />
				</Row>
				<Row className="buttons-row">
					<Col>
						<MainButton text={t("create new league")} size="medium" className="double"
							handler={() => handleToggle("create")}
						/>
					</Col>
					<Col>
						<MainButton text={t("join league")} size="medium" className="double"
							handler={() => handleToggle("join")}
						/>
					</Col>
				</Row>
				<Row className="boards-row global-row">
					<Col>
						<LeaguesList typeLeagues="common"/>
					</Col>
					<Col>
						<LeaguesList typeLeagues="private"/>
					</Col>
					<AdUnit adUnitType="rectangle" adUnitClassNames="col-auto"/>
				</Row>
			</Container>
			<LeaguesModal {...sendProps} />
		</div>
	);
};

Leagues.propTypes = {
	isLoading: PropTypes.bool
};

Leagues.defaultProps = {
	isLoading: false,
};


export default Leagues;


Leagues.displayName = "l";