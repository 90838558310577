import React, { useEffect } from "react";
import {
	Container, Row, Col, Spinner
} from "reactstrap";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Scrollbars } from "react-custom-scrollbars";
import Info from "static/assets/images/info-blue.svg";
import { useTranslation } from "react-i18next";

import { roundGamePropType } from "utils/propTypes";

import { actionGetRequestGameFootballPlayersPoints } from "redux/actions/roundGames";
import { actionSetPlayerInfoModalID } from "redux/actions/playersList";

import { ACTION_TYPES_ORDERED_GAME_INFO } from "helpers/constants/common";
import useQuery from "helpers/hooks/useQuery";

import { Team, Result } from "modules/common/GameItem";
import ModalWindow from "modules/common/ModalWindow";

import AdUnit from "modules/common/AdUnit"

const PlayerStatColumn = ({ data, isRight, actionSetPlayerInfoModalID }) => {
	const structuredData = {};
	data.forEach((item) => {
		structuredData[item.playerName] = structuredData[item.playerName] ? [
			...structuredData[item.playerName],
			item.points,
		] : [item.points];
	});
	const sortedData = data.sort((a, b) => {
		const compareValueA = a.count;
		const compareValueB = b.count;
		if (compareValueA < compareValueB) {
			return 1;
		}
		if (compareValueA > compareValueB) {
			return -1;
		}
		return 0;
	});

	return (
		<Col className='p-0' xs={6}>
			{sortedData.map((playerStats, index) => (
				<div key={`${playerStats.playerName}-${index}`} className='game-item__player-stats-wrapper'>
					<span className={`game-item__player-stats-inner ${isRight ? "is-right" : ""}`}>
						<span>{playerStats.playerName}</span>
						<span>&nbsp;|&nbsp;{playerStats.count}&nbsp;</span>
						<span>({playerStats.points})</span>
						<img
							onClick={() => { actionSetPlayerInfoModalID(playerStats.playerId); }}
							className='ml-1'
							src={Info}
							alt="open player details"
						/>
					</span>
				</div>
			))}
		</Col>
	);
};

const PlayersStatsBlock = ({ teamLeftID, teamRightID, data, title, key, actionSetPlayerInfoModalID }) => (
	<Row key={key} className='w-100 p-0 game-item__player-stats-block'>
		<Col xs={12} className='game-item__yellow-title'>
			{title}
		</Col>
		<PlayerStatColumn data={data[teamLeftID]} actionSetPlayerInfoModalID={actionSetPlayerInfoModalID} />
		<PlayerStatColumn data={data[teamRightID]} actionSetPlayerInfoModalID={actionSetPlayerInfoModalID} isRight />
	</Row>
);

const MatchInfoComponent = ({
	roundGame,
	roundGameItemRequestLoading,
	roundGamesResultsHashList,
	roundGamesResultsLoadingsHashList,
	actionGetRequestGameFootballPlayersPoints,
	actionSetPlayerInfoModalID,
}) => {
	const { t } = useTranslation("game_info_modal");
	const { id: roundGameId, status } = roundGame;
	const isScheduledGame = status === "scheduled";
	const isCanceledGame = status === "cancelled";
	const isForfeitedGame = status === "technical";
	const isMatchDataIsBeingAdded = status === "finished" && (roundGamesResultsHashList[roundGameId] && !Object.keys(roundGamesResultsHashList[roundGameId]).length);

	useEffect(() => {
		if (roundGameId) {
			actionGetRequestGameFootballPlayersPoints(roundGameId);
		}
	}, [roundGameId]);

	return (
	  <div>
	    <AdUnit />
      <Container contentClassName="game-info-modal" modalClassName="game-info-modal-wrapper">
        {!roundGameItemRequestLoading ?
          <Row className='justify-content-center'>
            <Team fullwidth {...roundGame.team_1} />
            <Result
              score1={isCanceledGame ? "-" : roundGame && roundGame.team_1_score}
              score2={isCanceledGame ? "-" : roundGame && roundGame.team_2_score}
              status={roundGame && roundGame.status}
              matchLocaleTime={roundGame.matchLocaleTime}
            />
            <Team fullwidth {...roundGame.team_2} />
          </Row>
          :
          <Row className='py-4 align-items-center justify-content-center'>
            <Spinner />
          </Row>
        }
        <Row className='justify-content-center'>
          {isScheduledGame &&
            <h4 className="mt-5 font-h4">
              {t("Match is scheduled")}
            </h4>
          }
          {isCanceledGame &&
            <h4 className="mt-5 font-h4">
              {t("Match is cancelled")}
            </h4>
          }
          {isForfeitedGame &&
            <h4 className="mt-5 font-h4">
              {t("Match is forfeited")}
            </h4>
          }
          {isMatchDataIsBeingAdded &&
            <h4 className="mt-5 font-h4">
              {t("Match data is being added")}
            </h4>
          }
        </Row>
        {roundGameId && !roundGamesResultsLoadingsHashList[roundGameId] && roundGamesResultsHashList[roundGameId] && !!Object.keys(roundGamesResultsHashList[roundGameId]).length &&
          <Scrollbars
            renderTrackHorizontal={props => <div {...props} className="track-horizontal" style={{ display: "none" }} />}
            renderThumbHorizontal={props => <div {...props} className="thumb-horizontal" style={{ display: "none" }} />}
            style={{ height: "60vh" }}
            className="game-item__scrollbar">
            {Object.keys(ACTION_TYPES_ORDERED_GAME_INFO).map(key => {
              if (roundGamesResultsHashList[roundGameId][key]) {
                return <PlayersStatsBlock
                  key={`${roundGame.team_1.id}-${roundGame.team_2.id}-${roundGameId}-${key}`}
                  teamLeftID={roundGame.team_1.id}
                  teamRightID={roundGame.team_2.id}
                  data={roundGamesResultsHashList[roundGameId][key]}
                  title={ACTION_TYPES_ORDERED_GAME_INFO[key]}
                  actionSetPlayerInfoModalID={actionSetPlayerInfoModalID}
                />;
              }
              return null;
            })}
          </Scrollbars>
        }
        {roundGameId && roundGamesResultsLoadingsHashList[roundGameId] &&
          <Row className='pt-5 align-items-center justify-content-center'>
            <Spinner />
          </Row>
        }
      </Container>
    </div>
	);
};

const mapStateToProps = ({ roundGamesReducer }) => {
	const { roundGameItemRequestLoading, roundGamesResultsHashList, roundGamesResultsLoadingsHashList } = roundGamesReducer;
	return {
	  roundGameItemRequestLoading,
		roundGamesResultsHashList,
		roundGamesResultsLoadingsHashList
	};
};

export default connect(
	mapStateToProps,
	{
		actionGetRequestGameFootballPlayersPoints,
		actionSetPlayerInfoModalID,
	}
)(MatchInfoComponent);

PlayerStatColumn.propTypes = {
	data: PropTypes.array,
	isRight: PropTypes.bool,
	actionSetPlayerInfoModalID: PropTypes.func.isRequired,
};

PlayerStatColumn.defaultProps = {
	data: [],
	isRight: false,
};

MatchInfoComponent.propTypes = {
	className: PropTypes.string,
	roundGame: PropTypes.shape(roundGamePropType.resultGame).isRequired,
	roundGameItemRequestLoading: PropTypes.object.isRequired,
	roundGamesResultsHashList: PropTypes.object.isRequired,
	roundGamesResultsLoadingsHashList: PropTypes.object.isRequired,
	actionGetRequestGameFootballPlayersPoints: PropTypes.func.isRequired,
	actionSetPlayerInfoModalID: PropTypes.func.isRequired
};

MatchInfoComponent.defaultProps = {
	className: "",
};

PlayersStatsBlock.propTypes = {
	teamLeftID: PropTypes.number,
	teamRightID: PropTypes.number,
	data: PropTypes.object,
	title: PropTypes.string.isRequired,
	actionSetPlayerInfoModalID: PropTypes.func.isRequired,
	key: PropTypes.string,
};

PlayersStatsBlock.defaultProps = {
	teamLeftID: 0,
	teamRightID: 0,
	data: {},
	key: ""
};

MatchInfoComponent.displayName = "gim";
PlayersStatsBlock.displayName = "psb";
PlayerStatColumn.displayName = "ptc";