import React, { useEffect } from 'react';
import PropTypes from "prop-types";


const adUnitBillboardDivId = "adunit-billboard-responsive";
const adUnitBillboardDesktop = "/23281734176/serieafantasy.com_1150x300_billboard_desktop";
const adSizesBillboardDesktop = [[728,90],[970,90],[970,120],[970,188],[970,200],
                        [970,250],[970,300],[980,120],[980,240],[980,300],
                        [1000,200],[1000,250],[1100,200],[1100,300],[1150,300]];
const adUnitBillboardMobile = "/23281734176/serieafantasy.com_400x400_billboard_mobile";
const adSizesBillboardMobile = [[300,250],[300,300],[320,250],[320,320],[320,336],
                        [336,280],[336,320],[336,336],[400,400]];

const adUnitRectangleDivId = "adunit-rectangle-responsive";
const adUnitRectangleDesktop = "/23281734176/serieafantasy.com_336x336_desktop_lazy";
const adUnitRectangleMobile = "/23281734176/serieafantasy.com_336x336_mobile_lazy";
const adSizesRectangle = [[300,250],[300,300],[320,250],[320,320],[320,336],
                        [336,280],[336,320],[336,336]];

if (typeof window !== 'undefined') {
  window.googletag = window.googletag || {cmd: []};
  window.stpd = window.stpd || {que: []};

  window.googletag.cmd.push(() => {
    if (window.innerWidth >= 1200) {
      window.googletag.defineSlot(adUnitBillboardDesktop, adSizesBillboardDesktop, adUnitBillboardDivId).addService(window.googletag.pubads());
      window.googletag.defineSlot(adUnitRectangleDesktop, adSizesRectangle, adUnitRectangleDivId).addService(window.googletag.pubads());
    } else {
      window.googletag.defineSlot(adUnitBillboardMobile, adSizesBillboardMobile, adUnitBillboardDivId).addService(window.googletag.pubads());
      window.googletag.defineSlot(adUnitRectangleMobile, adSizesRectangle, adUnitRectangleDivId).addService(window.googletag.pubads());
    }
    window.googletag.pubads().enableSingleRequest();
    window.googletag.pubads().disableInitialLoad();
    window.googletag.pubads().collapseEmptyDivs();
    window.googletag.enableServices();

    window.stpd.que.push(() => {
      window.stpd.initialize();
    });
  });
}

const AdUnit = ({ adUnitType, adUnitClassNames }) => {
  let adUnitDivId = "";

  switch (adUnitType) {
    case "rectangle":
      adUnitDivId = adUnitRectangleDivId;
      break;
    default:
      adUnitDivId = adUnitBillboardDivId;
      adUnitClassNames = "ad-unit-billboard";
  }

  useEffect(() => {
    window.stpd.que.push(() => {
      window.stpd.initialize(adUnitDivId);
    });

    return () => {
      window.stpd.que.push(() => {
        window.stpd.terminateAdunit(adUnitDivId);
      });
    }
  }, []);

  return (
    <div id={adUnitDivId}  className={adUnitClassNames}>

    </div>
  )
}

export default AdUnit;
