import React from "react";
import { Container } from "reactstrap";

import Title from "modules/common/Title";
import Tabs from "modules/prizes/components/Tabs"

import AdUnit from "modules/common/AdUnit"

const Prizes = () => {
	return (
	  <div>
	    <AdUnit />
      <Container className="prizes global-container">
        <Title translation={{
            ns: "prizes",
            key: "Prizes",
        }}  />
        <Tabs />
      </Container>
    </div>
	);
};

export default Prizes;

Prizes.displayName = "prz";