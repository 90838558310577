import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Container, Row } from "reactstrap";
import { useMediaQuery } from "react-responsive/src";

import Title from "modules/common/Title";

import SocialComponent from "./Social";
import Article from "../containers/Article";

import AdUnit from "modules/common/AdUnit"

const NewsAndArticle = ({
	isLoading,
}) => {
	const isMobileResolution = useMediaQuery({
		query: "(max-device-width: 991px)"
	}) || window.innerWidth <= 991;

	const [debouncedIsLoading, setDebouncedIsLoading] = useState(isLoading);

	useEffect(() => {
		setTimeout(() => {
			setDebouncedIsLoading(isLoading);
		}, 50);
	}, [isLoading]);

	return (
	  <div>
	    <AdUnit />
      <Container className="news-and-social global-container">
        <Title translation={{
          ns: "news_and_article",
          key: "news_and_article"
        }} />
        <Row className={`justify-content-center mx-0 ${isMobileResolution && "flex-column align-items-center"}`}>
          <Article />
          {!debouncedIsLoading ? <SocialComponent /> : null}
        </Row>
      </Container>
		</div>
	);
};

NewsAndArticle.propTypes = {
	isLoading: PropTypes.bool.isRequired,
};

const mapStateToProps = state => {
	return {
		isLoading: state.newsReducer.selectedNewsItemRequestLoading,
	};
};

export default connect(
	mapStateToProps
)(NewsAndArticle);


NewsAndArticle.displayName = "nwac";